exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admissions-tsx": () => import("./../../../src/pages/admissions.tsx" /* webpackChunkName: "component---src-pages-admissions-tsx" */),
  "component---src-pages-debt-info-tsx": () => import("./../../../src/pages/debt-info.tsx" /* webpackChunkName: "component---src-pages-debt-info-tsx" */),
  "component---src-pages-governance-agm-tsx": () => import("./../../../src/pages/governance/agm.tsx" /* webpackChunkName: "component---src-pages-governance-agm-tsx" */),
  "component---src-pages-governance-board-tsx": () => import("./../../../src/pages/governance/board.tsx" /* webpackChunkName: "component---src-pages-governance-board-tsx" */),
  "component---src-pages-governance-committees-tsx": () => import("./../../../src/pages/governance/committees.tsx" /* webpackChunkName: "component---src-pages-governance-committees-tsx" */),
  "component---src-pages-governance-index-tsx": () => import("./../../../src/pages/governance/index.tsx" /* webpackChunkName: "component---src-pages-governance-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-share-information-dividend-policy-tsx": () => import("./../../../src/pages/share-information/dividend-policy.tsx" /* webpackChunkName: "component---src-pages-share-information-dividend-policy-tsx" */),
  "component---src-pages-share-information-index-tsx": () => import("./../../../src/pages/share-information/index.tsx" /* webpackChunkName: "component---src-pages-share-information-index-tsx" */),
  "component---src-pages-share-information-investment-calculator-tsx": () => import("./../../../src/pages/share-information/investment-calculator.tsx" /* webpackChunkName: "component---src-pages-share-information-investment-calculator-tsx" */),
  "component---src-pages-share-information-major-shareholders-tsx": () => import("./../../../src/pages/share-information/major-shareholders.tsx" /* webpackChunkName: "component---src-pages-share-information-major-shareholders-tsx" */),
  "component---src-pages-share-information-price-look-up-tsx": () => import("./../../../src/pages/share-information/price-look-up.tsx" /* webpackChunkName: "component---src-pages-share-information-price-look-up-tsx" */),
  "component---src-pages-share-information-series-tsx": () => import("./../../../src/pages/share-information/series.tsx" /* webpackChunkName: "component---src-pages-share-information-series-tsx" */),
  "component---src-templates-executive-tsx": () => import("./../../../src/templates/executive.tsx" /* webpackChunkName: "component---src-templates-executive-tsx" */)
}


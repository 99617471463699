import './src/styles/common.css'

// Remembers scroll position
export const shouldUpdateScroll = ({
  routerProps,
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const { pathname } = location

  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/privacy-policy`,
    `/`,
    `/ipo-documents`,
    `/news`,
    `/news/*`,
    `/news/**/*`,
    `/info`,
    `/shares`,
    `/executives/*`,
    `/governance/executive/*`,
    `/admissions`
  ]

  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0)
  }

  if (
    pathname === `/governance` &&
    routerProps.location.state.from !== `/governance/executive` &&
    routerProps.location.state.from !== `/governance/agm` &&
    routerProps.location.state.from !== `/governance/board` &&
    routerProps.location.state.from !== `/governance/committees`
  ) {
    window.scrollTo(0, 0)
  }

  if (
    pathname === `/reports`
  ) {
    window.scrollTo(0, 0)
  }

  return false
}

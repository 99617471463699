module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Universal Music Group | Investor Relations","short_name":"UMG | IR","start_url":"/","background_color":"#000","theme_color":"#2AB3E2","display":"standalone","icon":"src/images/icon.jpg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9c058b565c0bc9465d96210dc9a6c300"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TBXH963","defaultDataLayer":{"type":"object","value":{"platform":"gatsby","isSpa":true}},"routeChangeEventName":"pageview","includeInDevelopment":false,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
